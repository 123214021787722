<template>
  <v-row class="d-flex justify-center mt-0 mx-md-2 mx-n1 mb-0">
    <v-col cols="12" class="d-flex justify-end align-center pt-0 px-1">
      <div
        v-text="`${nuevo ? 'Nuevo p' : (editar ? 'Editar p' : 'P')}edido de stock ${!nuevo ? `Nº ${pedido.id}` : ''}`"
        style="font-size: 20px"
      ></div>
      <v-spacer></v-spacer>
      <v-expand-x-transition>
        <v-switch
          v-if="existe && pedido.estado == 1 && (permiso || pedido.usuario_grabacion == $store.state.username) && (observaciones == '' && selected.length == 0)"
          v-model="editar"
          label="Editar"
          class="d-flex align-center py-0 my-0"
          hide-details
          dense
        ></v-switch>
      </v-expand-x-transition>
    </v-col>
    <!-- FORMULARIO -->
    <v-col cols="12">
      <v-form ref="form">
        <v-row class="pt-4">
          <v-col cols="6" sm="3" md="2" class="py-0">
            <v-autocomplete
              v-if="nuevo"
              v-model="pedido.sucursal"
              label="Sucursal"
              item-text="nombre"
              item-value="id"
              :items="sucursales"
              :rules="editar ? [rules.required] : []"
              :readonly="articulos.length > 0"
              :filled="articulos.length > 0"
              validate-on-blur
              outlined
              dense
              @change="pedido.local = null; obtenerArticulos()"
            ></v-autocomplete>
            <v-text-field
              v-else
              v-model="pedido.sucursal_nombre"
              label="Sucursal"
              outlined
              readonly
              filled
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="5" md="4" xl="3" class="py-0">
            <v-autocomplete
              v-if="nuevo"
              v-model="pedido.local"
              label="Local"
              item-text="nombre"
              item-value="id"
              :items="locales_user.filter(loc => loc.sucursal == pedido.sucursal)"
              :rules="editar ? [rules.required] : []"
              validate-on-blur
              outlined
              dense
            ></v-autocomplete>
            <v-text-field
              v-else
              v-model="pedido.local_nombre"
              label="Local"
              outlined
              readonly
              filled
              dense
            ></v-text-field>
          </v-col>
          <template v-if="!nuevo">
            <v-col v-if="pedido.vendedor != null" cols="12" sm="4" xl="3" class="py-0">
              <v-text-field
                v-model="pedido.vendedor"
                label="Vendeddor"
                readonly
                outlined
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <v-autocomplete
                v-model="pedido.estado"
                label="Estado"
                item-text="nombre"
                item-value="id"
                :items="estados_pedido"
                readonly
                outlined
                filled
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="2" class="py-0">
              <v-text-field
                v-model="pedido.fecha"
                label="Fecha"
                readonly
                outlined
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="2" class="py-0">
              <v-text-field
                v-model="pedido.usuario_grabacion"
                label="Usuario grabación"
                readonly
                outlined
                filled
                dense
              ></v-text-field>
            </v-col>
          </template>
          <v-col cols="12" class="pb-0">
            <v-card>
              <v-data-table
                v-model="selected"
                :items="articulos.filter(art => art.estado != 'ELIMINADO')"
                :headers="headers"
                :items-per-page="-1"
                :show-select="!nuevo && !editar && existe && pedido.estado == 1 && permiso"
                :sort-by="!editar ? 'codigo' : ''"
                hide-default-footer
                sort-desc
                dense
              >
                <template v-slot:top>
                  <v-row v-if="editar" class="mx-2">
                    <v-col cols="6" sm="3" md="2">
                      Código
                      <v-text-field
                        v-model="codigo"
                        type="number"
                        class="mr-2"
                        :readonly="pedido.sucursal == null"
                        :filled="pedido.sucursal == null"
                        hide-details
                        outlined
                        dense
                        @blur="pedido.sucursal != null ? getDatosArticulo() : ''"
                      >
                        <template v-slot:append-outer>
                          <v-icon
                            color="info"
                            class="mt-1"
                            title="Buscar artículos"
                            :disabled="pedido.sucursal == null"
                            small
                            @click.stop="buscarArticulos()"
                          >
                            fas fa-search
                          </v-icon>
                        </template>
                      </v-text-field>
                      
                    </v-col>
                    <v-col cols="6" sm="4" xl="3">
                      Nombre
                      <v-text-field
                        v-model="nombre"
                        :readonly="pedido.sucursal == null || bloquear_nombre"
                        :filled="pedido.sucursal == null || bloquear_nombre"
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" xl="2">
                      <v-row>
                        <v-col>
                          Cantidad
                          <v-text-field
                            v-model="cantidad"
                            type="number"
                            class="mr-2"
                            :readonly="pedido.sucursal == null"
                            :filled="pedido.sucursal == null"
                            hide-details
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col>
                          Stock
                          <v-text-field
                            v-model="stock"
                            type="number"
                            hide-details
                            readonly
                            outlined
                            filled
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="2" class="d-flex align-end justify-end justify-sm-start mb-1">
                      <v-btn
                        color="success"
                        small
                        :disabled="pedido.sucursal == null"
                        @click="agregarArticulo()"
                      >
                        <v-icon small left>fas fa-plus</v-icon>
                        Agregar
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:[`item.nombre`]="{ item }">
                  <v-text-field
                    v-if="editar && item.codigo == null"
                    v-model="item.nombre"
                    hide-details
                    outlined
                    dense
                    @blur="validarCambios(item)"
                  ></v-text-field>
                  <div v-else>{{ item.nombre }}</div>
                </template>
                <template v-slot:[`item.cantidad`]="{ item }">
                  <v-text-field
                    v-if="editar"
                    v-model="item.cantidad"
                    class="my-1"
                    hide-details
                    outlined
                    dense
                    @blur="validarCambios(item)"
                  ></v-text-field>
                  <div v-else>{{ item.cantidad }}</div>
                </template>
                <template v-slot:[`item.stock`]="{ item }">
                  {{ dialogSelectArticulo.articulos.find(art => art.articulo_codigo == item.codigo) ? dialogSelectArticulo.articulos.find(art => art.articulo_codigo == item.codigo).stock : '' }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    color="error"
                    title="Quitar"
                    :disabled="!editar"
                    small
                    @click="quitarArticulo(item)"
                  >
                    fas fa-trash
                  </v-icon>
                </template>
                <template v-slot:[`item.enviado`]="{ item }">
                  <v-chip
                    :color="item.enviado == 1 ? 'success' : 'orange'"
                    small
                  >
                    {{ item.enviado == 1 ? 'E' : 'No e' }}nviado
                  </v-chip>
                </template>
                <template v-slot:no-data>
                  <v-alert
                    class="mx-auto mt-4"
                    max-width="400"
                    type="warning"
                    border="left"
                    dense
                    text
                  >
                    No hay artículos seleccionados
                  </v-alert>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
    <!-- OBSERVACIONES -->
    <v-col v-if="!nuevo && !editar && existe && ((pedido.estado == 1 && permiso) || pedido.estado == 4)" cols="12">
      Observaciones
      <v-textarea
        v-model.trim="observaciones"
        rows="3"
        :readonly="pedido.estado == 4"
        :filled="pedido.estado == 4"
        hide-details
        auto-grow
        outlined
      >
      </v-textarea>
    </v-col>
    <!-- BOTONES -->
    <v-col cols="12" class="d-flex justify-end">
      <v-btn
        :color="editar ? 'warning' : 'secondary'"
        @click="cerrar()"
      >
        {{ editar ? 'Cancelar' : 'Volver' }}
      </v-btn>
      <BtnConfirmar
        v-if="!nuevo && existe && pedido.estado == 1 && (permiso || pedido.usuario_grabacion == $store.state.username)"
        clase="ml-3"
        nombre="Anular"
        icono="fas fa-ban"
        color="error"
        :texto="`¿Desea <strong>anular</strong> el pedido de stock <strong> Nº ${id}</strong> ?`"
        :disabled="editar"
        @action="anular()"
      />
      <BtnConfirmar
        v-if="editar"
        clase="ml-3"
        @action="guardar()"
      />
      <BtnConfirmar
        v-if="!nuevo && !editar && existe && pedido.estado == 1 && permiso"
        clase="ml-3"
        nombre="Finalizar"
        icono="fas fa-check"
        color="success"
        :texto="`¿Desea <strong>finalizar</strong> el pedido de stock <strong> Nº ${id}</strong>
        ${selected.length == 0 ? '<strong>sin artículos</strong>' : `con <strong>${selected.length} de ${articulos.length}</strong> artículos`}
        seleccionados?`"
        :disabled="selected.length == 0 && observaciones == ''"
        @action="finalizar()"
      />
    </v-col>
    <!-- COMPONENTES -->
    <!-- Modal Seleccionar un Artículo -->
    <ModalSelectArticulo 
      :datos="dialogSelectArticulo"
      @setearModalSelectArt="setModalSelectArt"
      @setearArticulo="setArticulo"
    />
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import FechaPicker from '../../components/util/FechaPicker'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import ModalSelectArticulo from '../../components/util/ModalSelectArticulo'

export default {
  data () {
    return {
      id: this.$route.params.id,
      permiso: false,
      existe: false,
      editar: false,
      nuevo: false,
      observaciones: '',
      nombre: '',
      stock: null,
      codigo: null,
      cantidad: null,
      region: null,
      bloquear_nombre: false,
      selected: [],
      articulos: [],
      articulos_copy: [],
      headers: [
        { text: 'Código', value: 'codigo', align: 'end' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Cantidad', value: 'cantidad', align: 'center', width: 100 },
        { text: 'Stock', value: 'stock', align: 'center', width: 100, sortable: false, filterable: false },
        { text: 'Acciones', value: 'actions', align: 'center', sortable: false, filterable: false }
      ],
      pedido: {
        sucursal: null,
        local: null
      },
      pedido_copy: {},
      dialogSelectArticulo: {
        activo: false,
        articulos: [],
        bloquear_cant: false,
        bloquear_precio: false,
        cerrar_seleccion: false,
        con_stock: true,
        titulo_modal: 'Buscar Artículos',
        color: ''
      },
      rules: {
        required: value => !!value || 'Campo requerido'
      }
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('stock/get_estados_pedido')
    await this.$store.dispatch('vendedores/get_locales_user')
    this.permiso = await this.$store.dispatch('stock/get_permiso_pedido')
    this.$store.state.loading = false
    
    // si existe el id, obtengo los datos
    if (this.id) {
      this.$store.state.loading = true
      await this.$store.dispatch('stock/get_pedidos', { numero: this.id })
        .then(async (res) => {
          if (res.size == 1) {
            this.pedido = res.data[0]
            this.pedido_copy = JSON.parse(JSON.stringify(this.pedido))
            this.existe = true
            // si el pedido esta finalizado saco el headers de acciones y agrego el de estados
            if (this.pedido.estado == 2 || this.pedido.estado == 4) {
              this.observaciones = this.pedido.observaciones
              Object.assign(this.headers[3], { text: 'Estado', value: 'enviado', align: 'center' })
              this.headers.splice(4, 1)
            }
            // si el pedido no esta finalizado o anulado, obtengo los articulos de la sucursal para el stock
            if (this.pedido.estado != 2 && this.pedido.estado != 4) {
              this.obtenerArticulos()
            }
            // obtengo los detalles
            await this.$store.dispatch('stock/get_detalles_pedido', this.id)
              .then(async (resultado) => {
                this.articulos = resultado.data
                this.articulos_copy = JSON.parse(JSON.stringify(this.articulos))
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error'
                })
              })
          }
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      // si existe el pedido y esta pendiente y el que esta viendo tiene permisos, lo tomo
      if (this.existe && this.permiso && this.pedido.estado == 1) {
        await this.$store.dispatch('stock/tomar_pedido', this.id)
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
      }
      this.$store.state.loading = false
    }
    else {
      this.editar = true
      this.nuevo = true
      // si tiene una sucursal la selecciono
      if (this.una_sucursal) {
        this.pedido.sucursal = this.sucursales[0].id
        this.obtenerArticulos()
      }
    }
  },
  computed: {
    ...mapState('stock', ['estados_pedido']),
    ...mapState('vendedores', ['locales_user']),
    ...mapState(['sucursales']),
    ...mapGetters(['una_sucursal'])
  },
  components: {
    FechaPicker,
    BtnConfirmar,
    ModalSelectArticulo
  },
  watch: {
    async editar (val) {
      this.$refs.form.resetValidation()
      if (!this.nuevo) {
        this.$store.state.loading = true
        if (val) {
          // (si tiene permisos o si es el usuario que grabo) y el estado esta pendiente => marco como editando
          if ((this.permiso || this.pedido.usuario_grabacion == this.$store.state.username) && this.pedido.estado == 1) {
            await this.$store.dispatch('stock/editar_pedido', this.id)
              .catch(async error => {
                await this.$swal.fire({
                  icon: 'warning',
                  title: error.message
                })
                window.location.reload(true)
              })
          }
        }
        else {
          this.pedido = JSON.parse(JSON.stringify(this.pedido_copy))
          this.articulos = JSON.parse(JSON.stringify(this.articulos_copy))
          // si el estado del pedido es distinto a finalizado o anulado => lo libero
          if (this.pedido.estado != 2 && this.pedido.estado != 4) {
            await this.$store.dispatch(`stock/${this.permiso ? 'tomar_pedido' : 'liberar_pedido'}`, this.id)
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error'
                })
              })
          }
        }
        this.$store.state.loading = false
      }
    }
  },
  methods: {
    async finalizar () {
      if (this.observaciones == '') {
        return this.$store.dispatch('show_snackbar', {
          text: 'El campo observaciones es obligatorio',
          color: 'orange'
        })
      }
      this.$store.state.loading = true
      await this.$store.dispatch('stock/aceptar_pedido', {
        numero: this.id,
        observaciones: this.observaciones,
        articulos: this.selected.map(art => art.id)
      })
        .then((res) => {
          for (const enviado of this.selected) {
            let articulo = this.articulos.find(art => art.id == enviado.id)
            if (articulo) articulo.enviado = 1
          }
          this.selected = []
          this.pedido.estado = 4
          Object.assign(this.headers[3], { text: 'Estado', value: 'enviado', align: 'center' })
          this.$swal.fire({
            icon: 'success',
            title: res.message
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async guardar () {
      if (this.$refs.form.validate()) {
        this.$store.state.loading = true
        // NUEVO
        if (this.nuevo) {
          // valido que exista al menos un articulo
          if (this.articulos.length == 0) {
            this.$store.state.loading = false
            return this.$store.dispatch('show_snackbar', {
              text: 'Seleccione al menos un artículo',
              color: 'orange'
            })
          }
          await this.$store.dispatch('stock/nuevo_pedido', {
            sucursal: this.pedido.sucursal,
            local: this.pedido.local,
            articulos: this.articulos
          })
            .then((res) => {
              this.editar = false
              this.$swal.fire({
                icon: 'success',
                title: res.message
              })
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
        }
        // EDITAR
        else {
          // solo guardo si realmente modifico el array de articulos
          if (JSON.stringify(this.articulos) != JSON.stringify(this.articulos_copy)) {
            // valido que no haya eliminado todos los detalles
            if (this.articulos.filter(art => art.estado == 'ELIMINADO').length == this.articulos.length) {
              this.$store.state.loading = false
              return this.$store.dispatch('show_snackbar', {
                text: 'Seleccione al menos un artículo',
                color: 'orange'
              })
            }
            await this.$store.dispatch('stock/modificar_pedido', {
              numero: this.id,
              detalles: this.articulos
            })
              .then(async (res) => {
                await this.$swal.fire({
                  icon: 'success',
                  title: res.message
                })
                // refresco la pagina para que traiga los nuevos id de los detalles
                window.location.reload(true)
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error'
                })
              })
          }
          else {
            this.editar = false
            this.$store.dispatch('show_snackbar', {
              text: 'No se encontraron cambios en el detalle de artículos',
              color: 'info'
            })
          }
        }
        this.$store.state.loading = false
      }
    },
    async anular () {
      this.$store.state.loading = true
      await this.$store.dispatch('stock/anular_pedido', this.id)
        .then((res) => {
          this.pedido.estado = 2
          this.editar = false
          this.$swal.fire({
            icon: 'success',
            title: res.message
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async cerrar () {
      // libero el pedido siempre y cuando no este finalizado o anulado y tenga permisos o este editando
      if (!this.nuevo && this.pedido.estado != 2 && this.pedido.estado != 4 && (this.permiso || this.editar)) {
        this.$store.state.loading = true
        await this.$store.dispatch('stock/liberar_pedido', this.id)
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false
      }
      const routeData = this.$router.resolve({ path: '/pedidos-stock' })
      window.open(routeData.href, '_self')
    },
    async buscarArticulos () {
      // obtengo los articulos
      if (this.dialogSelectArticulo.articulos.length == 0){
        this.obtenerArticulos()
      }
      // abro el modal
      this.dialogSelectArticulo.activo = true
    },
    async obtenerArticulos () {
      const sucursal = this.sucursales.find(suc => suc.id == this.pedido.sucursal)
      if (!sucursal || !sucursal.region) {
        return this.$store.dispatch('show_snackbar', {
          text: 'La sucursal seleccionada no tiene una region definida',
          color: 'orange'
        })
      }
      if (this.region != sucursal.region) {
        this.$store.state.loading = true
        await this.$store.dispatch('stock/getArticulosReg', sucursal.region)
          .then((result) => {
            this.region = sucursal.region
            this.dialogSelectArticulo.articulos = result.data
          }).catch((err) => {
            return this.$store.dispatch('show_snackbar', {
              text: err.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false
      }
    },
    getDatosArticulo () {
      if (this.codigo) {
        const articulo = this.dialogSelectArticulo.articulos.find(art => art.articulo_codigo == this.codigo)
        if (articulo) {
          this.nombre = articulo.articulo_nombre
          this.stock = articulo.stock
          this.bloquear_nombre = true
        }
        else {
          this.nombre = ''
          this.bloquear_nombre = false
          this.$store.dispatch('show_snackbar', {
            text: 'No se encontró un artículo con código ' + this.codigo,
            color: 'error'
          })
          this.stock = null
          this.cantidad = null
          this.codigo = null
        }
      }
    },
    validarArticulo (articulo, cantidad, nombre, codigo) {
      if (this.nuevo) {
        // si es un pedido nuevo reviso que si el articulo fue agregado previamente
        if (articulo && articulo.codigo) {
          return this.$store.dispatch('show_snackbar', {
            text: `El artículo ${codigo} ya se encuentra en la lista`,
            color: 'info'
          })
        }
        this.articulos.push({
          codigo: codigo,
          nombre: nombre,
          cantidad: cantidad
        })
      }
      else {
        // si esta editando el pedido, reviso si el articulo ya existe
        if (articulo && articulo.codigo) {
          // si existe y fue eliminado, le actualizo la cantidad
          if (articulo.estado == 'ELIMINADO') {
            articulo.cantidad = cantidad
            articulo.estado = 'UPDATE'
          }
          // si existe y no fue eliminado, no lo agrego
          else {
            return this.$store.dispatch('show_snackbar', {
              text: `El artículo ${codigo} ya se encuentra en la lista`,
              color: 'info'
            })
          }
        }
        // si no existe lo agrego como nuevo
        else {
          this.articulos.push({
            id: null,
            codigo: codigo,
            nombre: nombre,
            cantidad: cantidad,
            estado: 'NUEVO'
          })
        }
      }
    },
    agregarArticulo () {
      if (this.cantidad <= 0) {
        return this.$store.dispatch('show_snackbar', {
          text: 'La cantidad debe ser mayor a 0',
          color: 'orange'
        })
      }
      if (!this.nombre) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Ingrese el nombre del artículo',
          color: 'orange'
        })
      }
      let articulo = this.articulos.find(i => i.codigo == this.codigo)
      this.validarArticulo(articulo, this.cantidad, this.nombre, this.codigo)
      this.codigo = null
      this.nombre = ''
      this.stock = null
      this.cantidad = null
      this.bloquear_nombre = false
    },
    quitarArticulo (item) {
      // si es un pedido nuevo, elimino del array
      if (this.nuevo) {
        const index = this.articulos.indexOf(item)
        this.articulos.splice(index, 1)
      }
      // si esta editando y es nuevo elimino, sino actualizo el estado
      else {
        if (item.estado == 'NUEVO') {
          const index = this.articulos.indexOf(item)
          this.articulos.splice(index, 1)
        } else {
          item.estado = 'ELIMINADO'
        }
      }
    },
    validarCambios (item) {
      if (!this.nuevo) {
        if (item.estado != 'NUEVO') {
          let articulo = this.articulos.find(i => i.id == item.id)
          let articulo_copy = this.articulos_copy.find(i => i.id == item.id)
          if (JSON.stringify(articulo.nombre) != JSON.stringify(articulo_copy.nombre) || JSON.stringify(articulo.cantidad) != JSON.stringify(articulo_copy.cantidad)) {
            articulo.estado = 'UPDATE'
          } else {
            articulo.estado = 'ORIGINAL'
          }
        }
      }
    },
    setModalSelectArt (value) {
      this.dialogSelectArticulo.activo = value
    },
    setArticulo (item) {
      if (item.cantidad <= 0) {
        return this.$store.dispatch('show_snackbar', {
          text: 'La cantidad debe ser mayor a 0',
          color: 'orange'
        })
      }
      let articulo = this.articulos.find(i => i.codigo == item.articulo_codigo)
      this.validarArticulo(articulo, item.cantidad, item.articulo_nombre, item.articulo_codigo)
    }
  }
}
</script>