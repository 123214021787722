<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- FILTROS -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            small
            fab
            @click="open_window(null)"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Sucursal
                  <v-autocomplete
                    v-model="filtro.sucursal"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="sucursales"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-1">
                  Local
                  <v-autocomplete
                    v-model="filtro.local"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="locales_user.filter(loc => loc.sucursal == filtro.sucursal)"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4" xl="3" class="py-1">
                  <v-row>
                    <v-col>
                      Fecha desde
                      <FechaPicker
                        v-model="filtro.desde"
                        hide-details
                      />
                    </v-col>
                    <v-col>
                      Fecha hasta
                      <FechaPicker
                        v-model="filtro.hasta"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Número pedido
                  <v-text-field
                    v-model="filtro.numero"
                    type="number"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Estado
                  <v-autocomplete
                    v-model="filtro.estado"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="estados_pedido"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- DATA TABLE -->
      <v-data-table
        class="cebra elevation-2 mt-2"
        sort-by="id"
        :items="pedidos"
        :headers="headers"
        :search="search"
        :loading="load"
        :footer-props="{'items-per-page-options':[5, 10, 15, 100]}"
        sort-desc
        dense
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="3" xl="2">
              <SearchDataTable
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="info"
            title="Ver"
            class="mr-2"
            small
            @click="open_window(item.id)"
          >
            fas fa-eye
          </v-icon>
          <BtnConfirmar
            v-if="item.estado == 3 && permiso"
            icono="fas fa-undo"
            clase="mr-2"
            color="orange"
            title="Liberar"
            :texto="`¿Desea <strong>liberar</strong> el pedido Nº ${item.id}? Está tomado por ${item.usuario_estado}`"
            icon_button
            small
            @action="liberar(item)"
          />
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { get_nombre_obj_arr } from '../../util/utils'
import BtnFiltro from '../../components/util/BtnFiltro'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import SearchDataTable from '../../components/util/SearchDataTable'
import FechaPicker from '../../components/util/FechaPicker'
import moment from 'moment'

export default {
  data () {
    return {
      panel: 0,
      load: false,
      permiso: false,
      search: '',
      headers: [
        { text: 'Número', value: 'id' },
        { text: 'Fecha', value: 'fecha', sortable: false },
        { text: 'Sucursal', value: 'sucursal_nombre' },
        { text: 'Local', value: 'local_nombre' },
        { text: 'Usuario grab.', value: 'usuario_grabacion' },
        { text: 'Estado', value: 'estado_nombre' },
        { text: 'Usuario estado', value: 'usuario_estado' },
        { text: 'Fecha estado', value: 'fecha_estado', sortable: false },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false }
      ],
      pedidos: [],
      filtro: {
        sucursal: null,
        local: null,
        desde: null,
        hasta: null,
        numero: null,
        estado: null
      }
    }
  },
  async created () {
    // si tiene solo 1 sucursal la selecciona
    if (this.una_sucursal) {
      this.filtro.sucursal = this.sucursales[0].id
    }
    // obtengo los datos necesarios
    this.$store.state.loading = true
    await this.$store.dispatch('stock/get_estados_pedido')
    await this.$store.dispatch('vendedores/get_locales_user')
    this.permiso = await this.$store.dispatch('stock/get_permiso_pedido')
    this.$store.state.loading = false
  },
  computed: {
    ...mapState('stock', ['estados_pedido']),
    ...mapState('vendedores', ['locales_user']),
    ...mapState(['sucursales']),
    ...mapGetters(['una_sucursal'])
  },
  components: {
    BtnFiltro,
    BtnConfirmar,
    SearchDataTable,
    FechaPicker
  },
  methods: {
    async liberar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('stock/liberar_pedido', item.id)
        .then((res) => {
          item.estado = 1
          item.estado_nombre = get_nombre_obj_arr(this.estados_pedido, 'id', 1, 'nombre')
          item.usuario_estado = this.$store.state.username
          item.fecha_estado = moment(new Date()).format('DD/MM/YYYY')
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async buscar () {
      this.load = true
      this.search = ''
      this.pedidos = []
      await this.$store.dispatch('stock/get_pedidos', this.filtro)
        .then((res) => {
          this.pedidos = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
    },
    open_window (id) {
      let path = '/pedido-stock'
      if (id) {
        path = `${path}/${id}`
      }
      const routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_self')
    },
    limpiar () {
      this.filtro = {
        sucursal: null,
        local: null,
        desde: null,
        hasta: null,
        numero: null,
        estado: null
      }
    }
  }
}
</script>