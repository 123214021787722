<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="600"
      :persistent="load"
      scrollable
      >
      <v-card>
        <v-card-title>
          Detalle de Series
          <v-spacer></v-spacer>
          <div class="mr-3">
            <SearchDataTable v-model="search"/>
          </div>
          <v-btn icon @click="dialog = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-title v-if="dialog" class="body-1 d-flex px-3 pt-3 pb-0">
          <div>
            <strong>Articulo:</strong> {{ datos.art_series[0].articulo_codigo }} - {{ datos.nombre }}<br>
            <strong>Cantidad:</strong> {{ datos.cantidad }}
          </div>
        </v-card-title>

        <v-card-text class="pa-0">
          <v-data-table 
            class="cebra"
            :headers="headers"
            :items="datos.art_series"
            :loading="load"
            :search="search"
            :items-per-page="-1"
            hide-default-footer
            dense>
          </v-data-table>
        </v-card-text>
        
        <v-card-actions class="d-flex justify-end pb-4">
          <v-btn
            color="secondary"
            :disabled="load"
            @click="dialog = false">
            Volver
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import SearchDataTable from '../util/SearchDataTable'

export default {
  name: 'ModalDetalleSerie',
  data () {
    return {
      load: false,
      search: '',
      items: [],
      headers: [ { text:'Series', align: 'center', value: 'serie'} ]//, { text:'Articulo', value: 'articulo_codigo' } ]
    }
  },
  props: {
    value: Boolean,
    datos: {
      type: Object,
      default: {
        nombre: '',
        art_series: [],
        cantidad: 0
      }
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
  },
  components: {
    SearchDataTable
  },
}
</script>

<style>

</style>