<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12" md="8" xl="6">
      <v-card>
        <v-form @submit.prevent="generar()">
          <v-row class="ma-0 py-3">
            <v-col cols="4" sm="3" md="2" class="py-0">
              Código
              <v-text-field
                v-model.trim="codigo"
                type="number"
                outlined
                dense
                @blur="buscar()"
              ></v-text-field>
            </v-col>
            <v-col cols="8" sm="5" md="6" class="py-0">
              Nombre
              <v-text-field
                v-model.trim="nombre"
                outlined
                readonly
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="d-flex justify-end align-center py-0">
              <v-btn
                color="info"
                class="mr-4"
                small
                fab
                @click="dialog = true"
              >
                <v-icon small>fas fa-search</v-icon>
              </v-btn>
              <v-btn
                type="submit"
                color="success"
              >
                <v-icon left>fas fa-barcode</v-icon>
                Generar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-col>
    <!-- COMPONENTES -->
    <ModalArticulosActivos
      v-model="dialog"
      @seleccionar="seleccionar"
    />
    <PDFViewer
      v-model="pdf_dialog"
      :pdf="pdf"
      :nombre="pdf_nombre"
      :titulo="pdf_nombre"
    />
  </v-row>
</template>

<script>
import ModalArticulosActivos from '../../components/util/ModalArticulosActivos'
import { barcodePDF } from '../../util/plantillas/pdfs'
import PDFViewer from '../../util/plantillas/PDFViewer'
import JsBarcode from 'jsbarcode'

export default {
  data () {
    return {
      codigo: '',
      nombre: '',
      dialog: false,
      pdf_dialog: false,
      pdf_nombre: '',
      pdf: null
    }
  },
  components: {
    ModalArticulosActivos,
    PDFViewer
  },
  methods: {
    async generar () {
      if (!this.codigo) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Seleccione un artículo',
          color: 'orange'
        })
      }
      let cantidad = null
      await this.$swal.fire({
        icon: 'question',
        title: 'Cantidad de columnas por impresión: ',
        confirmButtonText: '1',
        denyButtonText: '2',
        denyButtonColor: '#2E7D32',
        showDenyButton: true,
        focusConfirm: false
      })
        .then((res) => {
          if (res.isConfirmed) {
            cantidad = 1
          }
          else if (res.isDenied) {
            cantidad = 2
          }
        })
      if (cantidad) {
        let canvas = document.createElement('canvas')
        JsBarcode(canvas, this.codigo, {
          format: 'CODE128',
          width: 4,
          height: 50,
          margin: 0,
          displayValue: false
        })
        this.$store.state.loading = true
        this.pdf = await barcodePDF (canvas.toDataURL('image/png'), this.nombre, this.codigo.toString(), cantidad)
        this.pdf_nombre = `Código de barra ${this.codigo} - ${this.nombre}`.replaceAll('.', ',')
        this.pdf_dialog = true
        this.$store.state.loading = false
      }
    },
    async buscar () {
      if (!this.codigo) {
        this.nombre = ''
        return this.$store.dispatch('show_snackbar', {
          text: 'Ingrese un código de artículo',
          color: 'orange'
        })
      }
      this.$store.state.loading = true
      await this.$store.dispatch('articulos/get_articulo_activo', this.codigo)
        .then((res) => {
          this.nombre = res.data.nombre
        }).catch((error) => {
          this.codigo = ''
          this.nombre = ''
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'orange'
          })
        })
      this.$store.state.loading = false
    },
    seleccionar (articulo) {
      this.codigo = articulo.codigo
      this.nombre = articulo.nombre
      this.dialog = false
    }
  }
}
</script>