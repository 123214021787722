<template>
  <v-dialog
    v-model="dialog"
    width="800"
    :persistent="load"
    scrollable
  >
    <v-card>
      <v-card-title>
        Artículos
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          class="cebra"
          sort-by="nombre"
          :search="search"
          :loading="load"
          :headers="headers"
          :items="articulos_activos"
          :footer-props="{'items-per-page-options':[10, 15, 50, 100]}"
          dense
        >
          <template v-slot:top>
            <v-row class="pb-2">
              <v-col cols="12" sm="6">
                <SearchDataTable
                  v-model="search"
                />
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              color="success"
              title="Agregar"
              class="mr-2"
              small
              @click="$emit('seleccionar', item)"
            >
              fas fa-check-circle
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import SearchDataTable from './SearchDataTable'

export default {
  data () {
    return {
      load: false,
      search: '',
      headers: [
        { text: 'Código', value: 'codigo', align: 'end' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Categoría', value: 'categoria_nombre' },
        { text: 'Acciones', value: 'actions', align: 'center', sortable: false, filterable: false }
      ]
    }
  },
  props: {
    value: Boolean
  },
  computed: {
    ...mapState('articulos', ['articulos_activos']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    SearchDataTable
  },
  watch: {
    async dialog (val) {
      if (val && this.articulos_activos.length == 0) {
        this.load = true
        await this.$store.dispatch('articulos/get_articulos_activos')
        this.load = false
      } else {
        this.search = ''
      }
    }
  }
}
</script>